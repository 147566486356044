<!--
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-07 15:32:12
 * @listEditTime: Do not edit
-->
<template>
    <el-dialog  title="编辑模块" :close-on-click-modal="false" :append-to-body="true" :lock-scroll="false" modal @close="closeFunc"
        :visible="eidtDialogType">
        <div class="editMain">
            <div class="menuList">
                <div class="navBox" @click="addItemFunc">
                    + 添加新文章
                </div>

                <div class="bttomBox">
                    <div v-for="(item, index) in newsCategorysList" :key="index" class="itemBox"
                        :class="{ 'itemBox2': editListMode.id === item.id }" @click="getItemFunc(item, index)">
                        <div class="badge" v-if="item?.newType" >new</div>
                            <div style="width: 70%;padding: 10px 0;">{{ item.title }}</div>

                        <div style="height: 100%;width: 30%;position:relative">
                            <img :src="getImageSrc(item[mapping['src']])" alt="" style="height: 100%;width: 100%;" v-if="item[mapping['src']]!=''"/>

                            <div class="topMenu" v-show="editListMode.id === item.id" >
                                <el-dropdown trigger="click" >
                                    <span class="el-dropdown-link">
                                    <el-button>编辑</el-button>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item  @click.native="onTheMove(item,index)">上移动</el-dropdown-item>
                                    <el-dropdown-item  @click.native="underTheMove(item,index)">下移动</el-dropdown-item>
                                    <el-dropdown-item  @click.native="delBlockItemFunc(item,index)">删除</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="editMenu" >
                <el-empty :image-size="200" style="height: 725px;" v-if="!newsCategorysList.length" ></el-empty>
                <el-form v-if="newsCategorysList.length" label-width="80px" :model="editListMode" style="height: 100%;" ref="imgFromList">
                    <el-form-item label="标题">
                        <el-input v-model="editListMode.title"></el-input>
                    </el-form-item>
<!--                    <el-form-item label="封面图片">-->
<!--                        <el-upload class="avatar-uploader" action="" :show-file-list="false"  :http-request="onUpload"  v-for="imgSrc in editListMode[mapping['src']] "  :key="imgSrc">-->
<!--                            <img v-if="imgSrc"  :src="imgSrc" class="avatar">-->

<!--                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--                        </el-upload>-->
<!--                    </el-form-item>-->

                  <el-form-item label="图片: " prop="images">
                    <c-upload-image v-model="editListMode[mapping['src']]"></c-upload-image>
                  </el-form-item>
                    <el-form-item label="发布时间">
                        <el-date-picker v-model="editListMode[mapping['date']]" type="datetime" placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="编辑内容">
                        <div style="border: 1px solid #ccc;">
<!--                            <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor"-->
<!--                                :defaultConfig="toolbarConfig" :mode="mode" />-->
                            <Editor style="height: 250px; overflow-y: hidden;" v-model="editListMode.content"
                                :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />

<!--                          <editor-textbus v-model="editListMode.content"></editor-textbus>-->
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="savefunc" v-if="addItemStatus">新增</el-button>
                        <el-button @click="editfunc" v-else>保存修改</el-button>


                         <el-button style="margin-left: 30rpx" @click=" editfunc((that)=>{that.eidtDialogType=false})">保存并关闭编辑器</el-button>

                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {mapGetters,mapActions} from "vuex";
import { uploadImage } from '@/api/common/upload'
import { updateNews,getNewsDetail,createNews,deleteNews} from '@/api/news/index'


import "@wangeditor/editor/dist/css/style.css"
import CUploadImage from "@/components/upload/image.vue";
import EditorTextbus from "@/components/editor/textbus/index.vue";
export default {
    components: {EditorTextbus, CUploadImage, Editor, Toolbar },
    props: {
        cardIndex: {
            type: Object,
            default: () => {}
        },
        eidtDialogType: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            category_id: "",
             column_id:"",
            editListMode: {
                title: "",
                images: [],
                content: '',
            },
            addItemStatus:false,
            mapping:{},
            cardList: "",
            cardChild: "",
            newsCategorysList:[],
            editor: null,
            toolbarConfig: {},
            editorConfig: { placeholder: '请输入内容...' },
            mode: 'default', // or 'simple'
            nameType:"",
            radio:"",
            createName:"",
            postId:"",
        }
    },
    mounted() {
        this.init()

    },

    computed: {

        /**
         * @Author: YangXin
         * @Description: 从store获取元素
         * @Date: 2024-08-07 18:23:53
         * @return {*}
         */
        imgTextList() {
            return this.$store.getters.imgTextList;
        },
        currentDepartment() {
            return this.$store.getters["schema/schema"].value
        },

        ...mapGetters({
            getDataMapByKey: 'schema/dataMapByKey',    // 将 store.getters.count 映射到 this.count
        })

    },




    methods: {
        getImageSrc(src) {
          if (Array.isArray(src)) {
            return src.length > 0 ? src[0] : '';
          }
          return src;
        },
        /**
         * @Author: YangXin
         * @Description: 初始化获取修改的数组
         * @Date: 2024-08-07 18:18:03
         * @return {*}
         */
        init: function () {
            if (this.cardIndex) {
                this.cardList = this.currentDepartment[this.cardIndex.dataCardIndex],
                this.cardChild = this.cardList.children[this.cardIndex.cardTwoIndex]
            } else {
                this.cardList = this.currentDepartment[this.$parent.formListMode.dataCardIndex],
                this.cardChild = this.cardList.children[this.$parent.formListMode.cardChildIndex]
            }
            if (this.cardChild.children) {
                this.nameType = this.cardChild.children[0].dataSources.name
            }



            this.category_id = this.cardChild.children[0]?.dataSources.params.id
            this.column_id = this.cardChild.children[0]?.dataSources.params.column_id

            this.newsCategorysList = this.getDataMapByKey(this.cardChild.children[0]?.dataSources)
            // 初始化选择第一个

            this.mapping = this.cardChild.children[0]?.mapping;

            if(this.newsCategorysList){
                let item = this.newsCategorysList[0]
                this.editListMode = item;

                // this.editListMode.images = []
                // this.editListMode.id = item.id
            }else{

                this.newsCategorysList = []
            }

        },

        /**
         * @Author: YangXin
         * @Description: 添加文章
         * @Date: 2024-08-07 18:21:32https://mmbiz.qpic.cn/sz_mmbiz_gif/9Z5K66tFPiaxOPEtebskspQauYR4ZAABgDkhnwgHmmpbHHjTYpFLoSqfvxjuGlQ12icJ1GgeWQFPuQYibPiaMlASmg/640?wx_fmt=gif&from=appmsg&tp=webp&wxfrom=5&wx_lazy=1&wx_co=1
         * @return {*}
         */
        addItemFunc: function () {

           this.newsCategorysList.unshift({
                id:"",
                title: "",
                src: null,
                content: '',
                newType:true

            })

          this.addItemStatus = true

            if (this.newsCategorysList.length == 1) {
                this.editListMode = this.newsCategorysList[0]
            }
            // let data = this.getDataMapByKey(this.nameType)
            // console.log(data)


            // createNews({category_id:this.cardIndex.id,...this.editListMode}).then((res)=>{
            //     getNewsPage(this.cardIndex.id).then((item)=>{
            //         console.log(item)
            //     })
            // })
            // getNewsDetail(this.cardIndex.id).then((res)=>{
            //     console.log(res)
            // })
        },

        /**
         * @Author: YangXin
         * @Description: 上移动
         * @Date: 2024-08-15 14:52:12
         * @param {*} item
         * @param {*} index
         * @return {*}
         */
        onTheMove:function(item,index){
            this.newsCategorysList.splice(index - 1, 0, item)
            this.newsCategorysList.splice(index + 1, 1)
        },

        /**
         * @Author: YangXin
         * @Description: 下移动
         * @Date: 2024-08-15 14:52:33
         * @param {*} item
         * @param {*} index
         * @return {*}
         */
        underTheMove:function(item,index){
            this.newsCategorysList.splice(index + 2, 0, item)
            this.newsCategorysList.splice(index, 1)
        },

        /**
         * @Author: YangXin
         * @Description: 删除元素
         * @Date: 2024-08-15 14:58:00
         * @param {*} index
         * @return {*}
         */
        delBlockItemFunc:function(item,index){
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(item)

            this.newsCategorysList.splice(index, 1)
              if(this.newsCategorysList.length !== 0 ){
                this.editListMode = this.newsCategorysList[0]
              }
              if(item.user){
                 deleteNews(item.id)
              }

          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
        },

        /**
         * @Author: YangXin
         * @Description: 上传图片
         * @Date: 2024-08-13 16:32:47
         * @param {*} data
         * @return {*}
         */
        onUpload:function(data) {
            uploadImage(data.file, progress => {
                if (progress.total > 0) {
                progress.percent = (progress.loaded / progress.total) * 100
                }
                data.onProgress(progress)
            }).then(res => {
                this.editListMode.src = res.image_url
            })
        },
        /**
         * @Author: YangXin
         * @Description: 上传图片回显
         * @Date: 2024-08-07 18:18:07
         * @param {*} res
         * @param {*} file
         * @return {*}
         */
        handleAvatarSuccess(res, file) {
            this.editListMode.images = URL.createObjectURL(file.raw);
        },
        /**
         * @Author: YangXin
         * @Description: 编辑器方法
         * @Date: 2024-08-07 18:18:12
         * @param {*} editor
         * @return {*}
         */
        onCreated(editor) {
            this.editor = Object.seal(editor)
        },
        /**
         * @Author: YangXin
         * @Description: 点击获取修改的item
         * @Date: 2024-08-08 09:07:28
         * @param {*} item
         * @return {*}
         */
        getItemFunc: function (item, index) {
           this.editListMode = item
           this.postId = item.id
          if (item.id==""){

            this.addItemStatus = true;
          }else {
            this.addItemStatus = false;
          }
          // this.newsCategorysList[this.editListMode.id] =  this.editListMode
            // this.editListMode.id = item.id
            // this.editListMode.title = item.title
            // this.editListMode.images = item.src


        },

        /**
         * @Author: YangXin
         * @Description: 保存方法
         * @Date: 2024-08-08 10:17:20
         * @return {*}
         */
        savefunc: function () {
           let that = this;

            createNews({category_id:this.category_id,column_ids:[this.column_id],...this.editListMode}).then(res=>{

              that.loadDataSource({name:"category",params:{id:res.category_id,column_id:that.column_id}})

              that.$message({
                  message: '添加成功',
                  type: 'success'
                });

              that.addItemStatus=false;

            })
        },

      editfunc: function (call) {
        let that = this;

        updateNews( this.postId ,{category_id:this.category_id,column_ids:[this.column_id],...this.editListMode}).then(res=>{
          that.loadDataSource({name:"category",params:{id:res.category_id,column_id:that.column_id}})

          that.$message({
            message: '保存成功',
            type: 'success'
          });

          call(that)

        })
      },

        /**
         * @Author: YangXin
         * @Description:
         * @Date: 2024-08-08 10:41:22
         * @return {*}
         */
        closeFunc: function () {
            this.$parent.imgTextEditType = false
        },

        inputRadioFunc:function(val){
            console.log(val)
        },

        ...mapActions({
            loadDataSource: 'schema/LoadDataSource',
         }),

    },



}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
    width: 80% !important;
    border-radius: 10px;
background: #F0F0F0;


}

.flip-list-move {
  transition: transform 0.5s;
}
.badge{
    top: 0;
    // height: 20px;
    width: 40px;
    color: #409EFF;
    position: absolute;
}
// background: #F0F0F0;
// border-radius: 10px;
/deep/ .avatar-uploader .el-upload {
    border: 1px dashed #9c2b2b !important;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF !important;
}

/deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.editMain {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    .menuList {
        width: 30%;
        padding-top: 10px;
        padding: 10px;
        background: #ffffff;
        // border: 1px solid black;
        height: 725px;

        .navBox {
            cursor: pointer;
            height: 60px;
            width: 100%;
            text-align: center;
            line-height: 60px;
            font-size: 16px;
            font-family: Arial, Helvetica, sans-serif;
            border-radius: 8px;
            color: #0074AB;
            border: 1px solid #0074AB;
        }

        .navBox:hover,
        .itemBox:hover {
            background: #F0F0F0;
        }

        .bttomBox {
            padding-top: 10px;
            height: calc(100% - 60px);
            overflow-y: scroll !important;
            /* 或者 overflow-x: scroll; 根据需要选择 */
        }

        .itemBox {
            width: 100%;
            cursor: pointer;
            position: relative;
            height: 80px;
            padding: 5px 8px;
            border-radius: 4px;
            display: flex;
        }

        .itemBox2 {
            background: #F0F0F0;

            // box-shadow: 0px 18px 15px -3px rgba(0, 0, 0, 0.1);
        }
    }

    .editMenu {
        padding-top: 10px;
        margin-left:10px ;
        background: #ffffff;
        height: 100%;
        width: 69%;




        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }
}

.topMenu{
    top: 0;
    right: 0;
    position: absolute;
}
</style>
